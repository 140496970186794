import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { UserDTO } from '../dto/UserDTO';
import { UserAddressDTO } from '../dto/UserAddressDTO';
import { SellersDTO } from '../dto/SellersDTO';
import { UsersCommentDTO } from '../dto/UsersCommentDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  getAllPendingUsers(pageNumber: number, pageSize: number = 1) {
    const url = `${this.baseUrl}/pendingKYCUser?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllPendingUsersCount() {
    const url = `${this.baseUrl}/pendingKYCUser/count`;
    return this.http.get<any>(url).pipe(first());
  }
  private baseUrl = environment.API_URL + '/admin/users';
  userDTO: any;
  // private apiBaseUrl = '/api/login';
  constructor(private http: HttpClient) {}

  getAllUsers(pageNumber: number, pageSize: number = 1): Observable<any> {
    const url = `${this.baseUrl}/getAllUsers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllUsersCount() {
    const url = `${this.baseUrl}/getAllUsers/count`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllAdmin(pageNumber: number, pageSize: number = 1): Observable<any> {
    const url = `${this.baseUrl}/getAllAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllAdminCount() {
    const url = `${this.baseUrl}/getAllAdmin/count`;
    return this.http.get<any>(url).pipe(first());
  }

  getUserDetails(id: string): Observable<any> {
    const url = `${this.baseUrl}/getUserById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  deleteUser(userId: string): Observable<any> {
    const url = `${this.baseUrl}/markInactive/${userId}`;
    return this.http.post<any>(url, userId).pipe(first());
  }
  generateOTP(phoneNumber: string): Observable<any> {
    const url = `${this.baseUrl}/generateOTP/${phoneNumber}`;
    return this.http.post<any>(url, phoneNumber).pipe(first());
  }
  validateOTP(phoneNumber: string, otp: string): Observable<any> {
    const url = `${this.baseUrl}/validateOTP`;
    const requestBody = { phoneNumber: phoneNumber, otp: otp };
    return this.http.post<any>(url, requestBody);
  }
  roles() {
    const url = `${this.baseUrl}/getAllTypes`;
    return this.http.get<any>(url).pipe(first());
  }
  save(userDTO: UserDTO) {
    const url = `${this.baseUrl}/save`;
    return this.http.post<UserDTO>(url, userDTO).pipe(first());
  }
  getAllAddressOfUser(userId: string) {
    const url = `${this.baseUrl}/getAllAddress/${userId}`;
    return this.http.get<any>(url).pipe(first());
  }
  edit(userDTO: UserDTO) {
    const url = `${this.baseUrl}/edit`;
    return this.http.post<UserDTO>(url, userDTO).pipe(first());
  }
  markAddressAsDefault(userId: string, addressId: string) {
    const url = `${this.baseUrl}/markAddressDefault/${userId}/${addressId}`;
    return this.http.post<any>(url, userId).pipe(first());
  }
  getAddressById(addressId: string) {
    const url = `${this.baseUrl}/getAddressById/${addressId}`;
    return this.http.get<any>(url).pipe(first());
  }
  editAddress(addressDTO: UserAddressDTO) {
    const url = `${this.baseUrl}/editAddress`;
    return this.http.post<UserAddressDTO>(url, addressDTO).pipe(first());
  }
  deleteAddress(addressId: any) {
    const url = `${this.baseUrl}/deleteAddressById/${addressId}`;
    return this.http.post<any>(url, addressId).pipe(first());
  }
  markUserAsSeller(sellersDTO: SellersDTO) {
    const url = `${this.baseUrl}/markAsSeller`;
    return this.http.post<SellersDTO>(url, sellersDTO).pipe(first());
  }
  getAllRegionForUser(id: string) {
    const url = `${this.baseUrl}/getAllRegionForUser/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllRegion() {
    const url = `${this.baseUrl}/getAllRegion`;
    return this.http.get<any>(url).pipe(first());
  }
  saveRegion(region: any) {
    const url = `${this.baseUrl}/addRegion`;
    const regionDTO = {
      id: region.id,
      state: region.state,
      name: region.name,
      deliveryTime: region.deliveryTime,
      deliveryFee: region.deliveryFee,
    };
    return this.http.post<any>(url, regionDTO).pipe(first());
  }
  getRegionCodes(id: any) {
    const url = `${this.baseUrl}/getRegionCodes/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  saveRegionCOdes(regionDTO: any) {
    const url = `${this.baseUrl}/saveRegionCodes`;
    return this.http.post<any>(url, regionDTO).pipe(first());
  }
  verifyPhoneNumber(phone: string) {
    const url = `${this.baseUrl}/verifyPhoneNumber/${phone}`;
    return this.http.get<any>(url).pipe(first());
  }
  getUsersComment(id: string) {
    const url = `${this.baseUrl}/getUserComment/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  addUsersComment(userCommentDTO: UsersCommentDTO) {
    const url = `${this.baseUrl}/saveUsersComment`;
    return this.http.post<UsersCommentDTO>(url, userCommentDTO).pipe(first());
  }
  getAllRM() {
    const url = `${this.baseUrl}/getAllRM`;
    return this.http.get<any>(url).pipe(first());
  }
  getSellerDetails(id: string) {
    const url = `${this.baseUrl}/getSellerDetails/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  addAddress(addressDTO: UserAddressDTO) {
    const url = `${this.baseUrl}/addAddress`;
    return this.http.post<any>(url, addressDTO).pipe(first());
  }
  markAsBillingAddress(userId: string, id: string) {
    const url = `${this.baseUrl}/markAsBillingAddress/${userId}/${id}`;
    return this.http.post<any>(url, userId).pipe(first());
  }
  updateKYCStatus(status: string, id: string) {
    const url = `${this.baseUrl}/updateKYC/${status}/${id}`;
    return this.http.post<any>(url, status).pipe(first());
  }
  getAllSeller() {
    const url = `${this.baseUrl}/getAllSellers`;
    return this.http.get<any>(url).pipe(first());
  }
  isSuperAdmin() {
    const url = `${this.baseUrl}/isSuperAdmin`;
    return this.http.get<any>(url).pipe(first());
  }
}
