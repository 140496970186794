<div class="px-lg-5 py-lg-3">
  <div class="d-flex align-items-center mb-3">
    <button class="button" onclick="history.back()">Back</button>
    <div class="col-5 justify-content-end d-flex">
      <h3 class="mb-0">Notifications</h3>
    </div>
  </div>
  <div class="container mt-3 px-lg-0">
    <div
      class="d-flex flex-column"
      *ngFor="let notification of notifications; let last = last"
    >
      <div
        class="notification-item d-flex justify-content-between px-lg-2 py-lg-2"
      >
        <div class="col-10" (click)="goToDetails(notification)" role="button">
          {{ notification.notificationText }}
        </div>
        <div class="col-2">{{ notification.createdAt | date : "short" }}</div>
      </div>
      <div *ngIf="!last" class="notification-line"></div>
    </div>
  </div>
</div>
