import { Component, OnInit } from '@angular/core';
import { SystemConfig } from '../services/system-config.services';
import { Router } from '@angular/router';
import { TaxCodeDTO } from '../dto/TaxCodeDTO';

@Component({
  selector: 'app-taxcode',
  templateUrl: './taxcode.component.html',
  styleUrls: ['./taxcode.component.scss'],
})
export class TaxcodeComponent implements OnInit {
  taxCodeDTO: TaxCodeDTO = new TaxCodeDTO();
  isEdit: boolean = false;
  pageNumber: number = 0;
  pageSize: number = 10;
  pageSizes: number[] = [5, 10, 20, 50];
  count: number = 0;

  addTaxCode() {
    this.router.navigate(['/taxcode/save']);
  }
  goToEdit(id: any, gstValue: any) {
    this.isEdit = true;
    this.taxCodeDTO.id = id;
    this.taxCodeDTO.total_gst = gstValue;
    this.editTaxCode();
    // this.router.navigate(['/taxcode/edit/',id])
  }
  deleteTaxCode(id: any, gstValue: any) {
    // this.isEdit=false;
    if (confirm('Are you sure to delete the HSN Code?')) {
      this.taxCodeDTO.id = id;
      this.taxCodeDTO.total_gst = gstValue;
      this.taxCodeDTO.isDeleted = true;
      this.editTaxCode();
    }
    // this.router.navigate(['/taxcode/edit/',id])
  }
  taxCodes: any;
  ngOnInit(): void {
    this.getAllTaxCodes();
  }
  constructor(private sysconfig: SystemConfig, private router: Router) {}

  getAllTaxCodes() {
    this.sysconfig
      .getAllTaxCodes(this.pageNumber, this.pageSize)
      .subscribe((data) => {
        this.taxCodes = data;
        console.log(this.taxCodes);
      });
    this.sysconfig.getAllTaxCodesCount().subscribe((data) => {
      this.count = data.count;
    });
  }
  editTaxCode() {
    this.sysconfig.editTaxCode(this.taxCodeDTO).subscribe((data) => {
      window.location.reload();
    });
  }
  onPageChange(newPageNumber: number) {
    this.pageNumber = newPageNumber;
    this.getAllTaxCodes();
  }
  onPageSizeChange() {
    this.pageNumber = 0; // Reset to first page whenever page size changes
    this.getAllTaxCodes();
  }
}
