<nav class="sidenav d-flex flex-column">
  <div class="d-flex align-items-center justify-content-center py-4">
    <div [routerLink]="['/']" role="button">
      <img width="100px" src="assets/e-store.png" alt="" />
    </div>
  </div>
  <div class="">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link text-white" routerLink="/home">Home</a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-white"
          (click)="toggleCollapse('usersNestedItems')"
          [class.collapsed]="!isCollapsed('usersNestedItems')"
          role="button"
          aria-expanded="false"
          aria-controls="usersNestedItems"
          >Users</a
        >
        <div
          class="collapse"
          id="usersNestedItems"
          [ngClass]="{ show: !isCollapsed('usersNestedItems') }"
        >
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link text-white nav-child" routerLink="/users/add"
                >Add Users</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-white nav-child" routerLink="/users/list"
                >Users List</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/users/pendingkyc"
                >Pending KYC</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/users/adminlist"
                >Admin List</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/users/sellerlist"
                >Sellers List</a
              >
            </li>
            <!-- Add more nested items as needed -->
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-white"
          (click)="toggleCollapse('productsNestedItems')"
          [class.collapsed]="!isCollapsed('productsNestedItems')"
          role="button"
          >Products</a
        >
        <div
          class="collapse"
          id="productsNestedItems"
          [ngClass]="{ show: !isCollapsed('productsNestedItems') }"
        >
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/products/add"
                >Add Product</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/products/list"
                >Product Lists</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/products/search"
                >Search Product</a
              >
            </li>

            <!-- Add more nested items as needed -->
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-white"
          (click)="toggleCollapse('ordersNestedItems')"
          [class.collapsed]="!isCollapsed('ordersNestedItems')"
          role="button"
          >Orders</a
        >
        <div
          class="collapse"
          id="ordersNestedItems"
          [ngClass]="{ show: !isCollapsed('ordersNestedItems') }"
        >
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/orders/listing"
                >Listing</a
              >
            </li>
            <!-- Add more nested items as needed -->
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-white"
          (click)="toggleCollapse('electricianNestedItems')"
          [class.collapsed]="!isCollapsed('electricianNestedItems')"
          role="button"
          >Electricians</a
        >
        <div
          class="collapse"
          id="electricianNestedItems"
          [ngClass]="{ show: !isCollapsed('electricianNestedItems') }"
        >
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/electrician/add"
                >Add Electrician</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/electrician/list"
                >Electrician Lists</a
              >
            </li>

            <!-- Add more nested items as needed -->
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-white"
          (click)="toggleCollapse('confignesteditems')"
          [class.collapsed]="!isCollapsed('confignesteditems')"
          role="button"
          >System Configuration</a
        >
        <div
          class="collapse"
          id="confignesteditems"
          [ngClass]="{ show: !isCollapsed('confignesteditems') }"
        >
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/usertype/list"
                >User Type</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/productcategory/list"
                >Product Category</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-white nav-child" routerLink="/taxcode"
                >Tax/HSN Code</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-white nav-child" routerLink="/brandinfo"
                >Brand Info</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white nav-child"
                routerLink="/regionconfig"
                >Delivery Regions</a
              >
            </li>

            <!-- Add more nested items as needed -->
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link text-white" routerLink="/history">History</a>
      </li>
    </ul>
  </div>
  <div
    *ngIf="isLoggedIn()"
    class="mt-auto d-flex bg-danger justify-content-center"
  >
    <div class="btn btn-block text-white" (click)="signOut()">Logout</div>
  </div>
</nav>
