import { Injectable } from '@angular/core';
import { AddProductDTO } from '../dto/AddProduct.DTO';
import { Observable, first } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddPriceDTO } from '../dto/AddPriceDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddProductService {
  private baseUrl = environment.API_URL + '/admin/product';
  private baseSystemUrl = environment.API_URL + '/admin/system';
  constructor(private http: HttpClient) {}

  saveItem(id: string, product: AddProductDTO): Observable<any> {
    let url: string;
    if (id) {
      url = `${this.baseUrl}/saveProduct`;
      const updatedProduct = { ...product, id };
      return this.http.post<any>(url, updatedProduct).pipe(first());
    } else {
      url = `${this.baseUrl}/saveProduct`;
      return this.http.post<any>(url, product).pipe(first());
    }
  }
  getCategory(): Observable<any> {
    const url = `${this.baseUrl}/getAllCategory`;
    return this.http.get<any>(url).pipe(first());
  }
  getSubCategory(categoryId: string): Observable<any> {
    const url = `${this.baseUrl}/getAllSubcategory?categoryId=${categoryId}`;
    console.log('API URL:', url);
    return this.http.get<any>(url).pipe(first());
  }
  getAllProduct(
    pageNumber: number,
    pageSize: number = 1,
    sort: string = 'basePrice,asc,generalMargin,desc'
  ): Observable<any> {
    const url = `${this.baseUrl}/getAll?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllProductCount() {
    const url = `${this.baseUrl}/getAll/count`;
    return this.http.get<any>(url).pipe(first());
  }
  getProductDetails(id: string): Observable<any> {
    const url = `${this.baseUrl}/getProductById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  deleteProduct(productID: string): Observable<any> {
    const url = `${this.baseUrl}/deleteProduct/${productID}`;
    return this.http.post<any>(url, productID).pipe(first());
  }
  uploadImage(id: string, files: any[]): Observable<any> {
    const formData = new FormData();
    formData.append('id', id);
    files.forEach((image) => {
      formData.append('files', image.file);
    });
    const url = `${this.baseUrl}/uploadImage/${id}`;
    return this.http.post<any>(url, formData).pipe(first());
  }
  getProductImageList(id: string) {
    const url = `${this.baseUrl}/getProductImageList/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  removeImageById(id: string) {
    const url = `${this.baseUrl}/removeImageById/${id}`;
    return this.http.post<any>(url, id).pipe(first());
  }
  getAllRelatedListing(id: string) {
    const url = `${this.baseUrl}/getAllRelatedListing/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  updateFlag(
    id: string,
    bestseller: boolean,
    bestoffer: boolean,
    isnew: boolean
  ) {
    const url = `${this.baseUrl}/setFlag/${id}`;
    const requestBody = {
      isBestseller: bestseller,
      isBestoffer: bestoffer,
      isNew: isnew,
    };
    return this.http.post<any>(url, requestBody).pipe(first());
  }
  updateListing(addPriceDTO: AddPriceDTO) {
    const url = `${this.baseUrl}/addPriceDetails`;
    return this.http.post<any>(url, addPriceDTO).pipe(first());
  }
  getAllBrandInfo(): Observable<any> {
    const url = `${this.baseSystemUrl}/getAllBrands`;
    return this.http.get<any>(url).pipe(first());
  }
  getDeletedItems() {
    const url = `${this.baseUrl}/getdeleteditem`;
    return this.http.get<any>(url).pipe(first());
  }
  addPriceDetails(productPricingDTO: any) {
    //addPriceDetails
    const url = `${this.baseUrl}/addPriceDetails`;
    return this.http.post<any>(url, productPricingDTO).pipe(first());
  }
  serachByDesc(searchterm: string) {
    const url = `${this.baseUrl}/search/${searchterm}`;
    return this.http.get<any>(url).pipe(first());
  }

  removePriceDetails(id: any) {
    //addPriceDetails
    const url = `${this.baseUrl}/removeListing/${id}`;
    return this.http.post<any>(url, null).pipe(first());
  }
}
