import { Component, OnInit } from '@angular/core';
import { SystemConfig } from '../services/system-config.services';
import { Router } from '@angular/router';
import { TaxCodeDTO } from '../dto/TaxCodeDTO';
import { BrandInfoDTO } from '../dto/BrandInfoDTO';

@Component({
  selector: 'app-brandinfo',
  templateUrl: './brandinfo.component.html',
  styleUrls: ['./brandinfo.component.scss'],
})
export class BrandInfoComponent implements OnInit {
  brandDTO: BrandInfoDTO = new BrandInfoDTO();
  isEdit: boolean = false;
  brands: any;
  file: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  pageSizes: number[] = [5, 10, 20, 50];
  count: number = 0;

  addBrandInfo() {
    this.router.navigate(['/brandinfo/save']);
  }
  goToEdit(id: any, brandName: any, brandDesc: any) {
    this.isEdit = true;
    this.brandDTO.id = id;
    this.brandDTO.brandName = brandName;
    this.brandDTO.brandDescription = brandDesc;
    this.editBrandInfo();
    // this.router.navigate(['/taxcode/edit/',id])
  }
  deleteBrandInfo(id: any) {
    // this.isEdit=false;
    if (confirm('Are you sure to delete the Brand?')) {
      this.brandDTO.id = id;
      // this.taxCodeDTO.total_gst = gstValue;
      this.brandDTO.isDeleted = true;
      this.editBrandInfo();
    }
    // this.router.navigate(['/taxcode/edit/',id])
  }
  taxCodes: any;
  ngOnInit(): void {
    this.getAllBrands();
  }
  constructor(private sysconfig: SystemConfig, private router: Router) {}

  getAllBrands() {
    this.sysconfig
      .getAllBrandInfo(this.pageNumber, this.pageSize)
      .subscribe((data) => {
        this.brands = data;
        console.log(this.brands);
      });
    this.sysconfig.getAllBrandInfoCount().subscribe((data) => {
      this.count = data.count;
    });
  }
  editBrandInfo() {
    this.sysconfig.editBrandInfo(this.brandDTO).subscribe((data) => {
      this.getAllBrands();
    });
  }
  onPageChange(newPageNumber: number) {
    this.pageNumber = newPageNumber;
    this.getAllBrands();
  }
  onPageSizeChange() {
    this.pageNumber = 0; // Reset to first page whenever page size changes
    this.getAllBrands();
  }
}
