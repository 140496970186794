<div>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="activeTab === 'products'"
        (click)="setActiveTab('products')"
        >Deleted Products</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="activeTab === 'categories'"
        (click)="setActiveTab('categories')"
        >Deleted Categories</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="activeTab === 'subcategories'"
        (click)="setActiveTab('subcategories')"
        >Deleted Subcategories</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="activeTab === 'brands'"
        (click)="setActiveTab('brands')"
        >Deleted Brands</a
      >
    </li>
  </ul>

  <div *ngIf="activeTab === 'products'">
    <h3>Deleted Products</h3>
    <ul>
      <li *ngFor="let product of deletedItems.product">
        {{ product.productHeading }} (ID: {{ product.id }})
      </li>
    </ul>
  </div>

  <div *ngIf="activeTab === 'categories'">
    <h3>Deleted Categories</h3>
    <ul>
      <li *ngFor="let category of deletedItems.productCategory">
        {{ category.categoryName }} (ID: {{ category.id }})
      </li>
    </ul>
  </div>

  <div *ngIf="activeTab === 'subcategories'">
    <h3>Deleted Subcategories</h3>
    <ul>
      <li *ngFor="let subcategory of deletedItems.productSubcategory">
        {{ subcategory.subcategoryName }} (ID: {{ subcategory.id }})
      </li>
    </ul>
  </div>

  <div *ngIf="activeTab === 'brands'">
    <h3>Deleted Brands</h3>
    <ul>
      <li *ngFor="let brand of deletedItems.brand">
        {{ brand.brandName }} (ID: {{ brand.id }})
      </li>
    </ul>
  </div>
</div>
