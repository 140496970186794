import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AddProductService } from '../services/add-product.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  activeTab: string = 'products';
  deletedItems: any = {
    product: [],
    productCategory: [],
    productSubcategory: [],
    brand: [],
  };

  constructor(private addProductService: AddProductService) {}

  ngOnInit() {
    this.fetchDeletedItems();
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  fetchDeletedItems() {
    this.addProductService.getDeletedItems().subscribe(
      (data) => {
        this.deletedItems = data;
      },

      (error) => {
        console.error('Error fetching deleted items', error);
      }
    );
  }
}
