import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../services/orders.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  notifications: any[] = [];
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService
  ) {}

  ngOnInit(): void {
    this.notificationService.getNotification().subscribe((data: any[]) => {
      this.notifications = data;
    });
  }
  goToDetails(notification: any) {
    // this.hideNotifications();
    if (notification.moduleType == 'ORDER') {
      this.router.navigate(['/orders/details/', notification.orderIdentifier]);
    } else if (notification.moduleType == 'USER') {
      this.router.navigate(['/users/details/', notification.userId]);
    }
  }
}
