import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  adminPhoneNumber: string = '';
  otp: string = '';
  generate: any;
  showOtpInput: boolean = false;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}

  submitForm() {
    console.log('Form submitted:');
    // Here you can add logic to submit the form data to a service or perform other actions
  }

  generateOtp() {
    if (!this.adminPhoneNumber) {
      console.error('Phone number is required.');
      return;
    }

    this.loginService.generateOTP(this.adminPhoneNumber).subscribe((data) => {
      this.generate = data;
      this.showOtpInput = true;
    });
  }

  validateOtp() {
    if (!this.otp) {
      console.error('Please enter the OTP.');
      return;
    }

    if (this.otp.length !== 6) {
      console.error('OTP must be exactly 6 digits.');
      return;
    }

    // Log the values to ensure they are populated correctly
    console.log('Admin Phone Number:', this.adminPhoneNumber);
    console.log('OTP:', this.otp);

    this.loginService.validateOTP(this.adminPhoneNumber, this.otp).subscribe(
      (response) => {
        console.log('OTP validation response:', response);
        if (response && response.success) {
          console.log('OTP validation successful');
          localStorage.setItem('token', response.token); // Assuming the token is returned in the response
          // Handle successful validation here
          this.router.navigate(['/']);
        } else {
          console.error('OTP validation failed:', response.message);
          alert("Incorrect OTP or User Type.");
          // Handle failed validation here
        }
      },
      (error) => {
        console.error('Error validating OTP:', error);
        // Handle errors here
      }
    );
  }
}
