import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../services/orders.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications: any[] = [];
  latestNotifications: any[] = [];
  unreadCount: number = 0;
  showNotifications: boolean = false;
  orderItemDetails: any;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService
  ) {}

  ngOnInit(): void {
    this.notificationService.getNotification().subscribe((data) => {
      this.notifications = data;
      this.latestNotifications = this.notifications.slice(0, 10);
      this.unreadCount = this.notifications.filter(
        (notification) => notification.notificationStatus === 'UNREAD'
      ).length;
    });
  }

  toggleNotifications(): void {
    this.showNotifications = !this.showNotifications;
  }
  hideNotifications() {
    this.showNotifications = false;
  }

  navigateToAllNotifications(): void {
    this.hideNotifications();
    this.router.navigate(['/all-notifications']);
  }
  goToDetails(notification: any) {
    this.hideNotifications();
    if (notification.moduleType == 'ORDER') {
      this.router.navigate(['/orders/details/', notification.orderIdentifier]);
    } else if (notification.moduleType == 'USER') {
      this.router.navigate(['/users/details/', notification.userId]);
    }
  }
}
