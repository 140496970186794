import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserTypeDTO } from '../dto/UserTypeDTO';
import { ProductCategoryDTO } from '../dto/ProductCategoryDTO';
import { ProductSubcategoryDTO } from '../dto/ProductSubcategoryDTO';
import { TaxCodeDTO } from '../dto/TaxCodeDTO';
import { BrandInfoDTO } from '../dto/BrandInfoDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SystemConfig {
  
  private baseUrl = environment.API_URL + '/admin/system';

  constructor(private http: HttpClient) {}

  getAllUserType(pageNumber: number, pageSize: number = 1): Observable<any> {
    const url = `${this.baseUrl}/getAllUserType?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllUserTypeCount() {
    const url = `${this.baseUrl}/getAllUserType/count`;
    return this.http.get<any>(url).pipe(first());
  }
  saveUserType(userTypeDTO: UserTypeDTO): Observable<any> {
    const url = `${this.baseUrl}/saveUserType`;
    return this.http.post<UserTypeDTO>(url, userTypeDTO).pipe(first());
  }

  editUserType(userTypeDTO: UserTypeDTO): Observable<any> {
    const url = `${this.baseUrl}/editUserType`;
    return this.http.post<UserTypeDTO>(url, userTypeDTO).pipe(first());
  }

  deleteUserType(id: String): Observable<any> {
    const url = `${this.baseUrl}/deleteUserType/${id}`;
    return this.http.post<any>(url, id).pipe(first());
  }

  getUserTypeById(id: String): Observable<any> {
    const url = `${this.baseUrl}/getUserTypeById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllcategory(pageNumber: number, pageSize: number = 1) {
    const url = `${this.baseUrl}/getAllCategory?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllcategoryCount() {
    const url = `${this.baseUrl}/getAllCategory/count`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllSubcategory(id: string) {
    const url = `${this.baseUrl}/getAllSubCategory/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  saveCategory(pc: ProductCategoryDTO) {
    const url = `${this.baseUrl}/saveCategory`;
    return this.http.post<any>(url, pc).pipe(first());
  }
  saveSubCategory(pc: ProductSubcategoryDTO) {
    const url = `${this.baseUrl}/saveSubCategory`;
    return this.http.post<any>(url, pc).pipe(first());
  }
  getCategoryById(id: string) {
    const url = `${this.baseUrl}/getCategoryById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  getSubCategoryById(id: string) {
    const url = `${this.baseUrl}/getSubCategoryById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllTaxCodes(pageNumber: number, pageSize: number = 1) {
    const url = `${this.baseUrl}/getTaxCodes?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllTaxCodesCount() {
    const url = `${this.baseUrl}/getTaxCodes/count`;
    return this.http.get<any>(url).pipe(first());
  }
  editTaxCode(tc: TaxCodeDTO) {
    const url = `${this.baseUrl}/editTaxCode`;
    return this.http.post<any>(url, tc).pipe(first());
  }

  addTaxCode(tc: TaxCodeDTO) {
    const url = `${this.baseUrl}/addTaxCode`;
    return this.http.post<any>(url, tc).pipe(first());
  }
  saveBrandInfo(bi: any, files: any[]) {
    const url = `${this.baseUrl}/addBrandInfo`;
    const formData: FormData = new FormData();
    formData.append('brandName', bi.brandName);
    formData.append('brandDescription', bi.brandDescription);
    // files.forEach((image) => {
    formData.append('file', files[0].file);
    // });
    return this.http.post<any>(url, formData).pipe(first());
  }
  getAllBrandInfo(pageNumber: number, pageSize: number = 1) {
    const url = `${this.baseUrl}/getAllBrands?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<any>(url).pipe(first());
  }
  getAllBrandInfoCount() {
    const url = `${this.baseUrl}/getAllBrands/count`;
    return this.http.get<any>(url).pipe(first());
  }
  getBrandInfoById(id: string) {
    const url = `${this.baseUrl}/getBrandInfoById/${id}`;
    return this.http.get<any>(url).pipe(first());
  }
  editBrandInfo(bi: BrandInfoDTO) {
    const url = `${this.baseUrl}/editBrandInfo`;
    return this.http.post<any>(url, bi).pipe(first());
  }
  addCategoryImage(catId:any, files: any[]){
    const url=`${this.baseUrl}/addcategoryimage/${catId}`;
    const formData: FormData = new FormData();
    formData.append('files',files[0].file);
    return this.http.post<any>(url, formData).pipe(first());
  }
  addSubcategoryImage(id: any, files: any[]) {
    const url=`${this.baseUrl}/addsubcategoryimage/${id}`;
    const formData: FormData = new FormData();
    formData.append('files',files[0].file);
    return this.http.post<any>(url, formData).pipe(first());
  }
}
