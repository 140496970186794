import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}
  getAuthToken(): string | null {
    return localStorage.getItem('token');
  }
  isAuthenticated(): boolean {
    // Check if the authentication token exists
    return !!this.getAuthToken();
  }
}
