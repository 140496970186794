import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-region-config',
  templateUrl: './region-config.component.html',
  styleUrls: ['./region-config.component.scss'],
})
export class RegionConfigComponent implements OnInit {
  allRegions: any;
  regionCodes: any;
  viewMoreInfo: boolean = false;
  addRegionCodes: any[] = [];
  removeRegionCodes: any[] = [];
  regionId: any;
  id: any;
  state: any;
  name: any;
  deliveryTime: any;
  showAddRegion: boolean = false;
  deliveryFee: any;
  constructor(private userService: UsersService) {}
  ngOnInit(): void {
    this.getAllRegion();
  }
  getAllRegion() {
    this.userService.getAllRegion().subscribe((data) => {
      this.allRegions = data;
    });
  }
  // Function to trigger View More Info
  viewMore(region: any) {
    this.regionId = region.id;
    this.userService.getRegionCodes(region.id).subscribe((data) => {
      this.regionCodes = data;
      this.viewMoreInfo = true;
      this.addRegionCodes = [];
      this.removeRegionCodes = [];
    });
  }

  // Function to switch to edit mode
  editRegion(region: any) {
    region.isEdit = true;
  }

  // Function to save the edited region data
  saveRegion(region: any) {
    this.userService.saveRegion(region).subscribe((data) => {
      region.isEdit = false;
      this.getAllRegion();
    });
  }
  saveNewRegion() {
    const region = {
      name: this.name,
      state: this.state,
      deliveryTime: this.deliveryTime,
      deliveryFee: this.deliveryFee,
    };
    this.userService.saveRegion(region).subscribe((data) => {
      // region.isEdit = false;
      this.showAddRegion = false;
      this.getAllRegion();
    });
  }

  // Function to cancel editing and revert changes
  cancelEdit(region: any) {
    region.isEdit = false;
    // You can add logic here to revert any unsaved changes
  }
  closeModal() {
    this.viewMoreInfo = false;
    this.showAddRegion = false;
  }

  // Function to remove a pincode by index
  removePincode(index: number) {
    this.removeRegionCodes.push({
      pincode: this.regionCodes[index].pincode,
      regionId: this.regionCodes[index].pincode,
      isDeleted: false,
      id: this.regionCodes[index].id,
    });
    this.regionCodes.splice(index, 1);
  }

  // Function to add a new pincode
  addPincode() {
    const newPincode = prompt('Enter new Pincode:');
    if (newPincode) {
      this.regionCodes.push({ pincode: newPincode });
      this.addRegionCodes.push({
        pincode: newPincode,
        regionId: this.regionId,
        isDeleted: false,
      });
      console.log(this.addRegionCodes);
    }
  }
  saveChanges() {
    for (let i = 0; i < this.addRegionCodes.length; i++) {
      const regionDTO = {
        regionId: this.addRegionCodes[0].regionId,
        isDeleted: false,
        pinCode: this.addRegionCodes[i].pincode,
      };
      this.userService.saveRegionCOdes(regionDTO).subscribe((data) => {
        this.addRegionCodes = [];
        this.viewMoreInfo = false;
      });
    }
    for (let i = 0; i < this.removeRegionCodes.length; i++) {
      const regionDTO = {
        regionId: this.removeRegionCodes[0].regionId,
        isDeleted: true,
        pinCode: this.removeRegionCodes[i].pincode,
        id: this.removeRegionCodes[i].id,
      };
      this.userService.saveRegionCOdes(regionDTO).subscribe((data) => {
        this.removeRegionCodes = [];
        this.viewMoreInfo = false;
      });
    }
  }
}
