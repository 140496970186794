<div class="m-3 border-primary">
  <div class="m-2">
    <button class="text-white bg-green p-2" (click)="addTaxCode()">
      Add New HSN
    </button>
  </div>
  <table class="table tableResponsive">
    <thead>
      <tr class="text-blue-lighter font16 fw-700">
        <th width="20%">
          <span>HSN Code</span>
        </th>
        <th width="20%"><span>GST Value</span></th>
        <th width="20%"><span>Action</span></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of taxCodes; let i = index"
        [class.alternate-row]="i % 2 == 1"
      >
        <td>{{ item.hsnCode }}</td>
        <td>
          {{ item.total_gst }}
          <div *ngIf="item.isEdit">
            <label for="firstName">Enter New GST Value:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              [(ngModel)]="item.total_gst"
              class="form-control"
              required
              #firstName="ngModel"
            />
          </div>
        </td>

        <td>
          <div class="d-flex gap-2">
            <button
              class="button text-white"
              (click)="item.isEdit = !item.isEdit"
              *ngIf="!item.isEdit"
            >
              Edit
            </button>
            <button
              class="button text-white"
              (click)="goToEdit(item.id, item.total_gst)"
              *ngIf="item.isEdit"
            >
              Save
            </button>
            <button
              class="button-red text-white"
              (click)="deleteTaxCode(item.id, item.total_gst)"
              *ngIf="!item.isEdit"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="m-3 justify-content-lg-end align-items-center d-flex mt-2">
  <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 0">
    &lt;
  </button>
  <select
    class="custom-select mx-1"
    [(ngModel)]="pageSize"
    (change)="onPageSizeChange()"
  >
    <option *ngFor="let size of pageSizes" [value]="size">
      {{ size }}
    </option>
  </select>
  <span
    >{{ pageNumber * pageSize + 1 }} - {{ (pageNumber + 1) * pageSize }} of
    {{ count }}</span
  >
  <button
    class="ms-1"
    (click)="onPageChange(pageNumber + 1)"
    [disabled]="(pageNumber + 1) * pageSize >= count"
  >
    &gt;
  </button>
</div>
