<div class="m-3 border-primary">
  <div class="m-2">
    <button class="text-white bg-green p-2" (click)="addBrandInfo()">
      Add New Brand
    </button>
  </div>
  <table class="table tableResponsive">
    <thead>
      <tr class="text-blue-lighter font16 fw-700">
        <th width="20%">Brand Logo</th>
        <th width="20%">
          <span>Brand Name</span>
        </th>
        <th width="20%"><span>Brand Desc</span></th>
        <th width="20%"><span>Action</span></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of brands; let i = index"
        [class.alternate-row]="i % 2 == 1"
      >
        <td>
          <img
            src="{{ item.brandLogoUrl }}"
            alt="brand logo"
            style="height: 2.5rem; margin-left: 1rem"
          />
        </td>
        <td>
          {{ item.brandName }}
          <div *ngIf="item.isEdit">
            <label for="firstName">Enter New Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              [(ngModel)]="item.brandName"
              class="form-control"
              required
              #firstName="ngModel"
            />
          </div>
        </td>
        <td>
          {{ item.brandDescription }}
          <div *ngIf="item.isEdit">
            <label for="firstName">Enter New Description</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              [(ngModel)]="item.brandDescription"
              class="form-control"
              required
              #firstName="ngModel"
            />
          </div>
        </td>

        <td>
          <div class="d-flex gap-2">
            <button
              class="button text-white"
              (click)="item.isEdit = !item.isEdit"
              *ngIf="!item.isEdit"
            >
              Edit
            </button>
            <button
              class="button text-white"
              (click)="goToEdit(item.id, item.brandName, item.brandDescription)"
              *ngIf="item.isEdit"
            >
              Save
            </button>
            <button
              class="button-red text-white"
              (click)="deleteBrandInfo(item.id)"
              *ngIf="!item.isEdit"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="m-3 justify-content-lg-end align-items-center d-flex mt-2">
  <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 0">
    &lt;
  </button>
  <select
    class="custom-select mx-1"
    [(ngModel)]="pageSize"
    (change)="onPageSizeChange()"
  >
    <option *ngFor="let size of pageSizes" [value]="size">
      {{ size }}
    </option>
  </select>
  <span
    >{{ pageNumber * pageSize + 1 }} - {{ (pageNumber + 1) * pageSize }} of
    {{ count }}</span
  >
  <button
    class="ms-1"
    (click)="onPageChange(pageNumber + 1)"
    [disabled]="(pageNumber + 1) * pageSize >= count"
  >
    &gt;
  </button>
</div>
