import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { OrderChatDTO } from '../dto/OrderChatDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private baseUrl = environment.API_URL + '/order/admin';

  constructor(private http: HttpClient) {}

  getAllOrder(
    pageNumber: number,
    pageSize: number = 1,
    sort: string = 'createdAt,asc,quantity,desc'
  ) {
    const url = `${this.baseUrl}/getallorder?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}`;
    return this.http.get<any>(url).pipe(first());
  }

  getAllOrderCount() {
    const url = `${this.baseUrl}/getallorder/count`;
    return this.http.get<any>(url).pipe(first());
  }

  getOrderDetail(identifier: string) {
    const url = `${this.baseUrl}/getorderdetail/${identifier}`;
    return this.http.get<any>(url).pipe(first());
  }
  updateEwayBillNumber(identifier: any, ewayBillNumber: any) {
    const url = `${this.baseUrl}/updateorder/${identifier}/${ewayBillNumber}`;
    // const payload = { orderStatus };
    return this.http.post<any>(url, null).pipe(first());
  }
  updateOrder(orderItemIdentifier: string, payload: any): Observable<any> {
    const url = `${this.baseUrl}/updateorder/${orderItemIdentifier}`;
    // const payload = { orderStatus };
    return this.http.post<any>(url, payload).pipe(first());
  }
  getAllOrderMessage(id: string, userType: string) {
    const url = `${this.baseUrl}/getAllMessageByOrderItemAndUserType/${id}/${userType}`;
    return this.http.get<any>(url).pipe(first());
  }
  deleteOrderMessage(id: string) {
    const url = `${this.baseUrl}/deleteMessageById/${id}`;
    return this.http.post<any>(url, id).pipe(first());
  }
  saveOrderMessage(orderChatDTO: OrderChatDTO) {
    const url = `${this.baseUrl}/sendMessage`;
    return this.http.post<any>(url, orderChatDTO).pipe(first());
  }
  generateInvoice(orderItemIdentifier: string) {
    const url = `${this.baseUrl}/generateinvoice/${orderItemIdentifier}`;
    return this.http.post<any>(url, {}).pipe(first());
  }
}
