<div class="loginContainer d-flex align-items-center justify-content-center">
  <div class="bg-white rounded2 px-4 py-4">
    <h3 class="text-blue mb-0">E-Store Admin Portal Login</h3>
    <form (ngSubmit)="submitForm()" #userForm="ngForm">
      <div class="form-group mt-3">
        <label class="text-blue" for="phoneNumber">Phone Number</label>

        <input
          type="numberOnly"
          class="form-control no-arrow"
          id="phoneNumber"
          name="phoneNumber"
          [(ngModel)]="adminPhoneNumber"
          required
          maxlength="10"
          #phoneNumberInput="ngModel"
        />

        <div *ngIf="phoneNumberInput.touched">
          <div *ngIf="phoneNumberInput.errors?.['required']">
            <small class="text-danger">Please enter a phone number.</small>
          </div>
          <div *ngIf="phoneNumberInput.errors?.['maxlength']">
            <small class="text-danger">Phone number must be 10 digits.</small>
          </div>
          <div
            *ngIf="
              phoneNumberInput.value?.length < 10 &&
              phoneNumberInput.value?.length > 0
            "
          >
            <small class="text-danger">Phone number must be 10 digits.</small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary mt-2"
          (click)="phoneNumberInput.valid && generateOtp()"
          [disabled]="
            !phoneNumberInput.valid ||
            !phoneNumberInput.value ||
            phoneNumberInput.value.length !== 10
          "
          *ngIf="!generate"
        >
          Generate OTP
        </button>
      </div>
      <div class="form-group" *ngIf="showOtpInput">
        <label class="text-blue" for="otp">OTP</label>
        <input
          type="numberOnly"
          class="form-control no-arrow"
          id="otp"
          name="otp"
          [(ngModel)]="otp"
          required
          maxlength="6"
          #otpInput="ngModel"
        />
        <div *ngIf="otpInput.touched">
          <div *ngIf="otpInput.errors?.['required']">
            <small class="text-danger">Please enter the OTP.</small>
          </div>
          <!-- <div *ngIf="otpInput.errors?.['minlength']">
              <small class="text-danger">OTP must be at least 6 digits.</small>
            </div> -->
          <div *ngIf="otp && otp.length !== 6">
            <small class="text-danger">OTP must be at least 6 digits.</small>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary mt-2"
          (click)="validateOtp()"
        >
          Validate OTP
        </button>
      </div>
    </form>
  </div>
</div>
