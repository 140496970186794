<div class="" *ngIf="isLoggedIn()">
  <div class="sidenav">
    <app-sidenav></app-sidenav>
  </div>
  <div class="main-content">
    <div class="header">
      <app-header></app-header>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="!isLoggedIn()">
  <!-- Display login screen here -->
  <app-login></app-login>
</div>
