import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private baseUrl = environment.API_URL + '/notification';

  constructor(private http: HttpClient) {}

  getNotification() {
    const url = `${this.baseUrl}/admin`;
    return this.http.get<any>(url).pipe(first());
  }
}
