import { Component } from '@angular/core';

@Component({
  selector: 'app-user-type-config',
  templateUrl: './user-type-config.component.html',
  styleUrls: ['./user-type-config.component.scss']
})
export class UserTypeConfigComponent {

}
