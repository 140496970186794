<div class="notification-wrapper">
  <div class="icon-container" (mouseenter)="toggleNotifications()">
    <img src="assets/noti-icon.svg" alt="" />
    <span class="badge-pill" *ngIf="unreadCount > 0">{{ unreadCount }}</span>
  </div>
  <div
    class="notification-container"
    *ngIf="showNotifications"
    (mouseleave)="hideNotifications()"
  >
    <h5>Notifications</h5>
    <div class="notifications-list">
      <div
        class="notification-item notification-link"
        *ngFor="let notification of latestNotifications"
        role="button"
        (click)="goToDetails(notification)"
      >
        <div class="notification-text">{{ notification.notificationText }}</div>
        <div class="notification-time">
          {{ notification.createdAt | date : "short" }}
        </div>
      </div>
    </div>
    <button (click)="navigateToAllNotifications()">See All</button>
  </div>
</div>
