import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { TaxcodeComponent } from './taxcode/taxcode.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { HistoryComponent } from './history/history.component';
import { RegionConfigComponent } from './region-config/region-config.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      {
        path: 'sidenav',
        component: SidenavComponent,
        canActivate: [AuthGuard],
      },
      { path: 'header', component: HeaderComponent },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'all-notifications',
    component: NotificationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'electrician',
    loadChildren: () =>
      import('./electrician/electrician.module').then(
        (m) => m.ElectricianModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'userType',
    loadChildren: () =>
      import('./user-type-config/user-type-config.module').then(
        (m) => m.UserTypeConfigModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'usertype',
    loadChildren: () =>
      import('./user-type-config/user-type-config.module').then(
        (m) => m.UserTypeConfigModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'productcategory',
    loadChildren: () =>
      import('./product-category/product-category.module').then(
        (m) => m.ProductCategoryConfigModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'taxcode',
    loadChildren: () =>
      import('./taxcode/taxcode.module').then((m) => m.TaxCodeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'brandinfo',
    loadChildren: () =>
      import('./brandinfo/brandinfo.module').then((m) => m.BrandInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction',
    loadChildren: () =>
      import('./transaction/transaction.module').then(
        (m) => m.TransactionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'regionconfig',
    component: RegionConfigComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
