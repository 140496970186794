import { Component } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  expandedSection: string | null = null;
  collapsedSections: { [key: string]: boolean } = {
    usersNestedItems: true,
    productsNestedItems: true,
  };

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  toggleCollapse(section: string) {
    if (this.expandedSection === section) {
      // If the clicked section is already expanded, collapse it
      this.expandedSection = null;
    } else {
      // If another section is expanded, collapse it and expand the clicked section
      this.expandedSection = section;
    }
  }

  isCollapsed(section: string): boolean {
    return this.expandedSection !== section;
  }
  signOut(): void {
    if(confirm("Are you sure to logout?")){
    const token = localStorage.getItem('token');
    if (token) {
      this.loginService.logout().subscribe(
        () => {
          console.log('Logged out successfully');
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        },
        (error) => {
          console.error('Logout failed:', error);
        }
      );
    } else {
      console.error('Token not found'); // Handle case where token is not found
    }
  }
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }
}
