import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-configure-pincodes',
  templateUrl: './configure-pincodes.component.html',
  styleUrls: ['./configure-pincodes.component.scss'],
})
export class ConfigurePincodesComponent {
  @Input() regionCodes: any;
}
