import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserTypeConfigComponent } from './user-type-config/user-type-config.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { TaxcodeComponent } from './taxcode/taxcode.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginService } from './services/login.service';
import { BrandInfoComponent } from './brandinfo/brandinfo.component';
import { OrdersComponent } from './orders/orders.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { HistoryComponent } from './history/history.component';
import { RegionConfigComponent } from './region-config/region-config.component';
import { ConfigurePincodesComponent } from './region-config/configure-pincodes/configure-pincodes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    HeaderComponent,
    HomeComponent,
    UserTypeConfigComponent,
    ProductCategoryComponent,
    TaxcodeComponent,
    LoginComponent,
    PageNotFoundComponent,
    BrandInfoComponent,
    OrdersComponent,
    NotificationComponent,
    NotificationListComponent,
    HistoryComponent,
    RegionConfigComponent,
    ConfigurePincodesComponent,
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, NgbModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    LoginService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
