import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private baseUrl = environment.API_URL;

  constructor(private http: HttpClient, private authService: AuthService) {}

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated(); // Check authentication status using AuthService
  }

  generateOTP(adminPhoneNumber: string): Observable<any> {
    const url = `${this.baseUrl}/users/generateadminotp`;
    const requestBody = { adminPhoneNumber };
    return this.http.post<any>(url, requestBody).pipe(first());
  }

  validateOTP(adminPhoneNumber: string, otp: string): Observable<any> {
    const url = `${this.baseUrl}/login/validateadminotp`;
    const requestBody = {
      adminPhoneNumber: adminPhoneNumber,
      otp: otp,
    };
    return this.http.post<any>(url, requestBody);
  }

  logout(): Observable<any> {
    const url = `${this.baseUrl}/login/logout`;
    const authToken = this.authService.getAuthToken(); // Get the authentication token from AuthService
    const headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });

    return this.http.post<any>(url, null, { headers: headers });
  }
  isSuperAdmin() {
    const url = `${this.baseUrl}/users/admin/isSuperAdmin`;
    return this.http.get<any>(url).pipe(first());
  }
}
