<div class="font24 d-flex col-12 justify-content-center text-white bg-blue">
  List of Regions
</div>
<div
  class="d-flex col-2 m-4 py-3 justify-content-evenly text-white bg-green"
  role="button"
  (click)="showAddRegion = true"
>
  Add New Region
</div>
<table class="border-primary p-4 gap-2 m-4">
  <thead>
    <tr class="d-flex">
      <!-- <th>ID</th> -->
      <th>State</th>
      <th>Region Name</th>
      <th>Delivery Time</th>
      <th>Delivery Fees</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let region of allRegions" class="d-flex p-2">
      <!-- Display Mode -->
      <ng-container *ngIf="!region.isEdit" class="p-2">
        <!-- <td>{{ region.id }}</td> -->
        <td>{{ region.state }}</td>
        <td>{{ region.name }}</td>
        <td>{{ region.deliveryTime }}</td>
        <td>{{ region.deliveryFee }}</td>
        <td class="d-flex gap-2">
          <button (click)="viewMore(region)">More Info</button>
          <button (click)="editRegion(region)">Edit</button>
        </td>
      </ng-container>

      <!-- Edit Mode -->
      <ng-container *ngIf="region.isEdit">
        <!-- <td><input [(ngModel)]="region.id" disabled /></td> -->
        <td><input [(ngModel)]="region.state" /></td>
        <td><input [(ngModel)]="region.name" /></td>
        <td><input [(ngModel)]="region.deliveryTime" /></td>
        <td><input [(ngModel)]="region.deliveryFee" type="number" /></td>
        <td class="d-flex gap-2">
          <button (click)="saveRegion(region)">Save</button>
          <button (click)="cancelEdit(region)" class="button-red">
            Cancel
          </button>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
<!-- Button to open modal -->

<!-- Modal structure -->
<div class="modal" *ngIf="viewMoreInfo">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>

    <h3>Region Pin Codes</h3>

    <!-- Pin Codes in 5 columns and 10 rows -->
    <div class="pin-grid">
      <div
        *ngFor="let code of regionCodes; let i = index"
        class="d-flex flex-row justify-content-between align-items-center"
      >
        {{ code.pincode }}
        <div
          role="button"
          (click)="removePincode(i)"
          class="button-red text-white p-1 font14"
          style="
            max-width: fit-content !important;
            max-height: fit-content !important;
          "
          *ngIf="regionCodes.length > 1"
        >
          X
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-evenly">
      <!-- Add button -->
      <div class="add-button">
        <button (click)="addPincode()">Add New Pincode</button>
      </div>
      <div class="add-button">
        <button (click)="saveChanges()">Save Changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" *ngIf="showAddRegion">
  <div class="modal-content">
    <h5 class="d-flex justify-content-center">Add New Region</h5>
    <div class="d-flex gap-2 align-items-center justify-content-between">
      <div class="d-flex gap-2">
        <label for="state"> State</label
        ><input name="state" type="text" [(ngModel)]="state" required />
      </div>
      <div class="d-flex gap-2">
        <label for="name"> Region Name</label
        ><input [(ngModel)]="name" name="name" type="text" required />
      </div>
      <div class="d-flex gap-2">
        <label for="deliveryTime"> Delivery Time (in Days)</label
        ><input
          [(ngModel)]="deliveryTime"
          name="deliveryTime"
          type="text"
          required
        />
      </div>
      <div class="d-flex gap-2">
        <label for="deliveryTime"> Delivery Fee </label
        ><input
          [(ngModel)]="deliveryFee"
          name="deliveryFee"
          type="text"
          required
        />
      </div>
      <button (click)="saveNewRegion()">Save</button>
      <button class="py- text-white button-red" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </div>
</div>
